<template>
  <v-container id="add-User" fluid tag="section" v-if="!loading">
    <base-material-card
      color="primary"
      icon="mdi-account"
      :title="gs.show_name(user)"
      class="px-5 py-3"
    >
      <v-row class="mx-0">
        <v-col cols="12" sm="4">
          <h3>{{ $t("social_number") }}</h3>
          <p>{{ user.socialNumber }}</p>
        </v-col>
        <v-col cols="12" sm="4">
          <h3>{{ $t("celphone") }}</h3>
          <p>{{ user.celPhone }}</p>
        </v-col>
        <v-col cols="12" sm="4">
          <h3>{{ $t("email") }}</h3>
          <p>{{ user.email }}</p>
        </v-col>
      </v-row>
      <v-tabs v-model="tab">
        <v-tab>{{ $t("portfolio") }}</v-tab>
        <v-tab>{{ $t("user_info") }}</v-tab>
      </v-tabs>
      <Portfolio v-if="tab == userInfoTabsEnums.USER_PORTFOLIO" :User="user" />
      <UserBasic
        v-if="tab == userInfoTabsEnums.USER_DATA"
        :user_prop="userData"
        :ReadOnly="true"
      />
    </base-material-card>
  </v-container>
  <v-row justify="center" v-else>
    <v-progress-circular indeterminate color="primary"> </v-progress-circular>
  </v-row>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import Portfolio from "./tabs/UserPortfolio.vue";
import UserBasic from "@/views/pages/user/index/form/tabs/Basic";
import { userInfoTabsEnums } from "./enums/UserInfoEnums";
import { UserTypeEnum } from "@/shared/enums/UserType";
import mixpanel from "mixpanel-browser";

export default {
  name: "UserInfo",

  components: {
    Portfolio,
    UserBasic,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: true,
    tab: 0,
    user: null,
    userData: null,
    userInfoTabsEnums,
    UserTypeEnum,
    userProfile: null,
  }),

  computed: {},

  async created() {
    await this.getUserInfo();
    await this.getUserProfile();

    if (this.user && this.userProfile.type === UserTypeEnum.Banker) {
      const investorId = this.$route.params.userId;

      mixpanel.track("AffiliateInvestorPortfolio_View", {
        partner_id: this.userProfile.partner.id,
        user_type: "banker",
        user_id: this.userProfile.id,
        investor_id: investorId,
        has_investments: this.user.investments.length > 0,
        has_suggestions: this.user.hasSuggestedAllocations,
        has_open_allocation_requests: this.user.hasOpenAllocationRequests,
      });
    }
  },
  watch: {
    async tab() {
      if (this.tab === userInfoTabsEnums.USER_DATA) {
        await this.getUserAllData();
      }
    },
  },
  methods: {
    async getUserProfile() {
      this.loading = true;
      await this.apiService
        .getRequest("user/user-profile")
        .then((resp) => {
          this.userProfile = resp.content;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async getUserInfo() {
      const userId = this.$route.params.userId;

      await this.apiService
        .postRequest(`investor/user-info`, { Id: userId })
        .then((resp) => {
          this.user = resp.content;
          this.loading = false;
        })
        .catch((error) => {
          this.edit = false;
          this.loading = false;
        });
    },
    async getUserAllData() {
      this.loading = true;

      const userId = this.$route.params.userId;
      await this.apiService
        .getRequest(`user/${userId}`)
        .then((resp) => {
          this.userData = JSON.parse(resp);
          this.loading = false;
        })
        .catch((error) => {
          this.edit = false;
          this.loading = false;
        });
    },
  },
};
</script>
