import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c(VContainer,{attrs:{"id":"add-User","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-account","title":_vm.gs.show_name(_vm.user)}},[_c(VRow,{staticClass:"mx-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("social_number")))]),_c('p',[_vm._v(_vm._s(_vm.user.socialNumber))])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("celphone")))]),_c('p',[_vm._v(_vm._s(_vm.user.celPhone))])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("email")))]),_c('p',[_vm._v(_vm._s(_vm.user.email))])])],1),_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v(_vm._s(_vm.$t("portfolio")))]),_c(VTab,[_vm._v(_vm._s(_vm.$t("user_info")))])],1),(_vm.tab == _vm.userInfoTabsEnums.USER_PORTFOLIO)?_c('Portfolio',{attrs:{"User":_vm.user}}):_vm._e(),(_vm.tab == _vm.userInfoTabsEnums.USER_DATA)?_c('UserBasic',{attrs:{"user_prop":_vm.userData,"ReadOnly":true}}):_vm._e()],1)],1):_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }